import {Colors, Text, Icon, Button, Divider, ButtonGroup} from "@blueprintjs/core";
import {useRecoilValue} from "recoil";
import {isMobileState} from "../recoil/isMobileState";

export default function Footer() {

    const buttonStyle = {fontWeight: 800, color: Colors.LIGHT_GRAY3};
    const isMobile = useRecoilValue(isMobileState);

    return (
        <footer
            className="bp5-dark"
            style={{
                width: '100%',
                backgroundColor: Colors.DARK_GRAY2,
                padding: isMobile ? '20px' : '20px 120px',
                position: ''
            }}>
            <ButtonGroup minimal={true} className="bp5-dark" style={{color: Colors.LIGHT_GRAY4}}>
                <Button small={isMobile} style={buttonStyle}>개인정보처리방침</Button>
                <Divider/>
                <Button small={isMobile} style={buttonStyle}>이메일무단수집거부</Button>
                <Divider/>
                <Button small={isMobile} style={buttonStyle}>이용약관</Button>
            </ButtonGroup>

            <Divider style={{margin: '13px -4px'}}/>

            <Text style={{color: Colors.GRAY2, fontSize: isMobile && '12px'}}>
                경기도 하남시 조정대로 150 아이테코 블루존 O-1004호
                <br/>
                <br/>
                <b>Tel.</b> 031-790-1655 / <b>Fax.</b> 031-790-1656
                <br/>
                Copyrightⓒ 2023 SEAHAUTOTECH All Rights Reserved.
            </Text>
        </footer>
    );
}
