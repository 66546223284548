import React, {useEffect, useState} from "react";
import {
    Card,
    TextArea,
    H6,
    HTMLTable,
    RadioGroup,
    Radio,
    Section,
    SectionCard,
    Checkbox, Icon, Tag, Colors, InputGroup, MenuItem, Menu, Button, FileInput, Popover
} from "@blueprintjs/core";
import heic2any from "heic2any";


import myAxios from "../myAxios";
import {loadingState} from "../recoil/loadingState";
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {alertState} from "../recoil/alertState";
import {titleCell} from "../style/TableStyle";
import moment from "moment";
import 'moment/locale/ko';
import BranchSelect from "./BranchSelect";
import {commaRegex, phoneNumberRegex} from "../util/RegexUtil";
import CategorySelect from "./CategorySelect";
import {imgUrl, imgUrlMd} from "../util/ImgUtil";
import {signInUserState} from "../recoil/signInUserState";
import {ROLE_EV_MAN_SALES, ROLE_PARTNER} from "../static/Roles";
import {APPROVED, CALCULATED, CANCELED, CONFIRMED, evWorkState, INPUTTED, ORDERED, WORKED} from "../static/EvWorkState";
import {isMobileState} from "../recoil/isMobileState";
import {DateInput} from "@blueprintjs/datetime";
import {useLocation} from "react-router-dom";

export default function EvWorkInfoComp({type, evWork, setEvWork, state, role, myTurn, isEdit}) {

    const location = useLocation()
    const [evPayments, setEvPayments] = useState([])
    const setLoading = useSetRecoilState(loadingState);
    const signInUser = useRecoilValue(signInUserState);
    const isMobile = useRecoilValue(isMobileState);

    useEffect(() => {
        myAxios.get('/category?code=EV-PAYMENT').then((res) => {
            setEvPayments([...res.data.children])
        })

        if(location.pathname === '/ev/order' && signInUser.role === ROLE_EV_MAN_SALES) {
            setEvWork({
                ...evWork,
                brand: {id:3, name: 'MAN'},
                paymentMethod: {id:4},
                items: [
                    {item: {id: 1001, name: '썬팅 필름', code: 'MTBK001'}, quantity: 1},
                    {item: {id: 1002, name: '바닥 매트', code: 'MTBK002'}, quantity: 1},
                    {item: {id: 1003, name: '4채널 블랙박스', code: 'MTBK003'}, quantity: 1},
                    {item: {id: 1004, name: '3채널 블랙박스', code: 'MTBK004'}, quantity: 1},
                    {item: {id: 1005, name: '덤프 적재함 카메라', code: 'MTBK005'}, quantity: 1},
                ]
            })
        }
        else if(location.pathname === '/ev/order') {
            setEvWork({
                ...evWork,
                paymentMethod: {id:4},
            })
        }
    }, []);

    useEffect(() => {
        document.getElementById('sc')?.scrollIntoView({inline: "center"});
    }, [state]);


    return (<>
        <Card style={{gridArea: 'g', overflow: 'auto',}}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 4,
                minWidth: 'fit-content',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Tag id={state === INPUTTED && 'sc'} large round icon={'plus'}
                     style={{opacity: state !== INPUTTED && '50%'}}>작업입력</Tag>
                <Icon icon={'caret-right'} size={20}
                      style={{opacity: state !== INPUTTED ? '20%' : '60%'}}/>
                <Tag id={state === INPUTTED && 'sc'} large round icon={'upload'}
                     style={{opacity: state !== ORDERED && '50%'}}>작업지시</Tag>
                <Icon icon={'caret-right'} size={20}
                      style={{opacity: state !== ORDERED ? '20%' : '60%'}}/>
                <Tag id={state === CONFIRMED && 'sc'} large round icon={'tick'}
                     style={{opacity: state !== CONFIRMED && '50%'}}>지점확인</Tag>
                <Icon icon={'caret-right'} size={20}
                      style={{opacity: state !== CONFIRMED ? '20%' : '60%'}}/>
                <Tag id={state === WORKED && 'sc'} large round icon={'build'}
                     style={{opacity: state !== WORKED && '50%'}}>작업완료</Tag>
                <Icon icon={'caret-right'} size={20}
                      style={{opacity: state !== WORKED ? '20%' : '60%'}}/>
                <Tag id={state === APPROVED && 'sc'} large round icon={'saved'}
                     style={{opacity: state !== APPROVED && '50%'}}>본사승인</Tag>
                <Icon icon={'caret-right'} size={20}
                      style={{opacity: state !== APPROVED ? '20%' : '60%'}}/>
                <Tag id={state === CALCULATED && 'sc'} large round icon={'dollar'}
                     style={{opacity: state !== CALCULATED && '50%'}}>정산완료</Tag>

                <Tag id={state === CANCELED && 'sc'} large round icon={'disable'} style={{
                    marginLeft: '20px',
                    opacity: state !== CANCELED && '50%'
                }}>작업취소</Tag>

            </div>
        </Card>
        <Section compact title={"작업정보"} style={{gridArea: 'a', overflow: 'auto'}}>
            <SectionCard>
                <HTMLTable style={{width: '100%'}}>
                    <tr>
                        <td style={titleCell}>작업지시 번호</td>
                        <td style={{minWidth: '90px'}}>{evWork.code}</td>
                    </tr>
                    <tr>
                        <td style={titleCell}>작업지시 일자</td>
                        <td>{evWork.orderedDate && moment(evWork.orderedDate).format('YY-MM-DD (ddd)')}</td>

                    </tr>
                    <tr>
                        <td style={titleCell}>정산완료 일자</td>
                        <td>
                            {evWork.states?.find((state) => state.state === CALCULATED)?.createDt &&
                                moment(evWork.states?.find((state) => state.state === CALCULATED)?.createDt).format('YY-MM-DD (ddd)')}
                        </td>
                    </tr>
                </HTMLTable>
            </SectionCard>
        </Section>
        <Section compact title={"작업지점"} style={{gridArea: 'b', overflow: 'auto'}}>
            <SectionCard>
                <HTMLTable style={{width: '100%'}}>
                    <tr>
                        <td style={titleCell}>지점명</td>
                        <td style={{minWidth: '70px'}}>{ (myTurn && state === INPUTTED) || isEdit ?
                            <BranchSelect branch={evWork.branch} defaultText={'-'}
                                          onItemSelect={(e) => setEvWork({...evWork, branch: e})}/>
                            : <>{evWork.branch?.name}</>
                        }</td>
                    </tr>
                    <tr>
                        <td style={titleCell}>지점코드</td>
                        <td>{evWork.branch?.code}</td>
                    </tr>
                    <tr>
                        <td style={titleCell}>연락처</td>
                        <td>{phoneNumberRegex(evWork.branch?.phoneNumber)}</td>
                    </tr>
                </HTMLTable>
            </SectionCard>
        </Section>
        <Section compact title={"고객정보"} style={{gridArea: 'c', overflow: 'auto'}}>
            <SectionCard>
                <HTMLTable style={{width: '100%',}}>
                    <tr>
                        <td style={titleCell}>BRAND</td>
                        <td>{!state || isEdit ?
                            <CategorySelect
                                category={evWork.brand} code={'EV-BRAND'} onItemSelect={(e) => {
                                if (evWork.brand?.id === e.id) return;
                                setEvWork({...evWork, brand: e, items: []});
                            }}/>
                            : <>{evWork.brand?.name}</>
                        }</td>
                        <td style={titleCell}>차대번호</td>
                        <td style={{minWidth: '60px'}}> {(!state || state === INPUTTED) || isEdit ?
                            <InputGroup style={{minWidth: '100px'}} defaultValue={evWork.vin}
                                        onChange={(e) => setEvWork({...evWork, vin: e.target.value})}/>
                            : <>{evWork.vin}</>
                        }</td>
                    </tr>
                    <tr>
                        <td style={titleCell}>MODEL</td>
                        <td>{(!state || state === INPUTTED ) || isEdit ?
                            <InputGroup style={{minWidth: '100px'}} defaultValue={evWork.vehicleModel}
                                        onChange={(e) => setEvWork({...evWork, vehicleModel: e.target.value})}/>
                            : <>{evWork.vehicleModel}</>
                        }</td>
                        <td style={titleCell}>Works No.</td>
                        <td style={{minWidth: '60px'}}>{(!state || state === INPUTTED) || isEdit ?
                            <InputGroup style={{minWidth: '100px'}} defaultValue={evWork.vehicleNumber}
                                        onChange={(e) => setEvWork({...evWork, vehicleNumber: e.target.value})}/>
                            : <>{evWork.vehicleNumber}</>
                        }</td>
                        {/*<td style={titleCell}>출고예정일자</td>정*/}
                        {/*<td>{!state ?*/}
                        {/*    <DateInput*/}
                        {/*        value={evWork.estimatedVehicleForwardingDate} placeholder={'YY-MM-DD'}*/}
                        {/*        inputProps={{style: {maxWidth: '200px', float: 'left'}, readOnly: true}}*/}
                        {/*        formatDate={(date) => moment(date).format('YY-MM-DD (ddd)')}*/}
                        {/*        parseDate={(str) => moment(str, 'YY-MM-DD (ddd)').date()}*/}
                        {/*        onChange={(date) => setEvWork({*/}
                        {/*            ...evWork,*/}
                        {/*            estimatedVehicleForwardingDate: date*/}
                        {/*        })}/>*/}
                        {/*    : <>{evWork.estimatedVehicleForwardingDate}</>*/}
                        {/*}</td>*/}
                    </tr>
                    <tr>
                        <td style={titleCell}>고객명</td>
                        <td>{(!state || state === INPUTTED) || isEdit ?
                            <InputGroup style={{minWidth: '100px'}} defaultValue={evWork.customerName}
                                        onChange={(e) => setEvWork({...evWork, customerName: e.target.value})}/>
                            : <>{evWork.customerName}</>
                        }</td>
                        <td style={titleCell}>연락처</td>
                        <td>{(!state || state === INPUTTED) || isEdit ?
                            <InputGroup style={{minWidth: '100px'}} defaultValue={evWork.customerPhoneNumber}
                                        onChange={(e) => setEvWork({...evWork, customerPhoneNumber: e.target.value})}/>
                            : <>{phoneNumberRegex(evWork.customerPhoneNumber)}</>
                        }</td>
                    </tr>
                </HTMLTable>
            </SectionCard>
        </Section>
        <Section compact title={"요청내용"} style={{gridArea: 'd', overflow: 'auto'}}>
            <SectionCard style={{display: 'flex', flexDirection: 'column', gap: 30}}>
                <div style={{minHeight: '150px'}}>
                    <H6>작업품목 입력</H6>
                    <HTMLTable style={{width: '100%'}}>
                        <tr>
                            <td style={{...titleCell, textAlign: 'center'}}>제품명</td>
                            <td style={{...titleCell, textAlign: 'center'}}>제품코드</td>
                            <td style={{...titleCell, textAlign: 'center', minWidth: '50px'}}>수량</td>
                            {/*<td style={{...titleCell, textAlign: 'center', minWidth: '50px'}}>금액</td>*/}
                            {/*<td style={{...titleCell, textAlign: 'center', minWidth: '100px'}}>비고</td>*/}
                        </tr>
                        {evWork.items?.map((item, idx) => {
                            return <tr>
                                <Popover
                                    targetTagName={'td'} placement={'right'}
                                    disabled={state && !isEdit }
                                    content={<Menu>
                                        <MenuItem icon={'delete'} text="삭제" intent="danger" onClick={() => {
                                            setEvWork({
                                                ...evWork,
                                                items: evWork.items.filter((it) => it.item.id !== item.item.id)
                                            })
                                        }}/>
                                    </Menu>}
                                >
                                    <div style={{
                                        textAlign: 'center', ...((!state || isEdit) && {
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                            color: 'blue'
                                        })
                                    }}>{item.item.name}</div>
                                </Popover>
                                <td style={{textAlign: 'center'}}>{item.item.code}</td>
                                <td style={{textAlign: 'center'}}>{(!state || isEdit) ?
                                    <InputGroup
                                        type={'number'} value={item.quantity} style={{width: '50px'}}
                                        onChange={(e) => {
                                            if (e.target.value < 0 || e.target.value > 100) return
                                            item.quantity = e.target.value;
                                            evWork.items[idx] = item;
                                            setEvWork({...evWork})
                                        }}/> : item.quantity}</td>
                                {/*<td style={{textAlign: 'center'}}>{'₩' + commaRegex(item.item.price * item.quantity)}</td>*/}
                                {/*<td style={{textAlign: 'center', maxWidth: '80px'}}>{!state ?*/}
                                {/*    <InputGroup style={{width: '80px'}} onChange={(e) => {*/}
                                {/*        item.note = e.target.value;*/}
                                {/*        evWork.items[idx] = item;*/}
                                {/*        setEvWork({...evWork})*/}
                                {/*    }}/> : item.note}</td>*/}
                            </tr>;
                        })}

                        {(!state|| isEdit) && <tr>
                            <td style={{textAlign: 'center'}}>
                                <CategorySelect id={evWork.brand?.id} disabled={!evWork.brand} onItemSelect={(e) => {
                                    if (evWork.items?.find((item) => item.item.id === e.id)) return;
                                    setEvWork({...evWork, items: [...(evWork?.items || []), {item: e, quantity: 1}]})
                                }}/>
                            </td>
                            <td style={{textAlign: 'center'}}></td>
                            <td style={{textAlign: 'center'}}></td>
                        </tr>}
                    </HTMLTable>
                </div>
                <div>
                    <H6>요청사항</H6>
                    <TextArea
                        style={{width: '100%', minHeight: '170px'}}
                        readOnly={(signInUser.role === ROLE_PARTNER || !myTurn) && !isEdit}
                        value={evWork.masterNote} onChange={(e) => {
                        setEvWork({...evWork, masterNote: e.target.value})
                    }}/>
                </div>
                <div>
                    <H6>결제구분 입력</H6>
                    <div style={{marginLeft: '4px'}}>
                        <RadioGroup
                            selectedValue={evWork.paymentMethod?.id || evPayments.find((evPayment) => evPayment.name === '신차 용품 쿠폰')?.id}
                            onChange={(e) => {
                                if (state && !isEdit) return;
                                setEvWork({
                                    ...evWork,
                                    paymentMethod: {id: parseInt(e.currentTarget.value)}
                                })
                            }}>
                            {evPayments?.map((evPayment) => {
                                return <Radio label={evPayment.name} value={evPayment.id}/>;
                            })}
                        </RadioGroup>
                    </div>
                </div>
                {/*<div style={{display: role === ROLE_PARTNER && 'none'}}>*/}
                {/*    <H6>MTBK</H6>*/}
                {/*    <div style={{marginLeft: '4px'}}>*/}
                {/*        <Checkbox label="MTBK 조회 가능" checked={evWork.isMTBK}*/}
                {/*                  onChange={(e) => {*/}
                {/*                      if (type !== 'order') return;*/}
                {/*                      setEvWork({...evWork, isMTBK: e.target.checked})*/}
                {/*                  }}/>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div
                    style={{display: !((state === WORKED && myTurn) || state === APPROVED || state === CALCULATED) && 'none'}}>
                    <H6>정산금액</H6>
                    <div style={{marginLeft: '4px', display: 'flex', alignItems: 'center'}}>
                        ₩{state === WORKED && myTurn ? <InputGroup
                        type={'number'} value={evWork.settlementAmount} style={{width: '90px', marginLeft: '3px'}}
                        onChange={(e) => {
                            setEvWork({
                                ...evWork, settlementAmount: e.target.value > 0 && e.target.value < 1000000000 ?
                                    e.target.value : null
                            })
                        }}/> : <>{commaRegex(evWork.settlementAmount)}</>}
                    </div>
                </div>
            </SectionCard>
        </Section>
        <Section compact title={"작업내용"}
                 style={{gridArea: 'e', display: isMobile && (!state || state === ORDERED) && 'none'}}>
            <SectionCard>
                <div style={{display: 'flex', flexDirection: 'column', gap: 20,}}>
                    {isMobile ? <HTMLTable style={{width: '100%'}}>
                            <tr style={{margin: 0}}>
                                <td style={{...titleCell, textAlign: 'center'}}>차대번호*</td>
                                <td style={{...titleCell, textAlign: 'center'}}>차량번호판</td>
                            </tr>
                            <tr>
                                <ImgCell name='vinUrl'/>
                                <ImgCell name='vehiclePlateUrl'/>
                            </tr>
                            <tr>
                                <td style={{...titleCell, textAlign: 'center'}}>고객동의서*</td>
                                <td style={{...titleCell, textAlign: 'center'}}></td>
                            </tr>
                            <tr>
                                <ImgCell name='customerConsentUrl'/>
                                <td/>
                            </tr>
                            <tr>
                                <td style={{...titleCell, textAlign: 'center'}}>제품1</td>
                                <td style={{...titleCell, textAlign: 'center'}}>제품2</td>
                            </tr>
                            <tr>
                                <ImgCell name='work1Url'/>
                                <ImgCell name='work2Url'/>
                            </tr>
                            <tr>
                                <td style={{...titleCell, textAlign: 'center'}}>제품3</td>
                                <td style={{...titleCell, textAlign: 'center'}}>제품4</td>
                            </tr>
                            <tr>
                                <ImgCell name='work3Url'/>
                                <ImgCell name='work4Url'/>
                            </tr>
                            <tr>
                                <td style={{...titleCell, textAlign: 'center'}}>제품5</td>
                                <td style={{...titleCell, textAlign: 'center'}}>제품6</td>
                            </tr>
                            <tr>
                                <ImgCell name='work5Url'/>
                                <ImgCell name='work6Url'/>
                            </tr>
                        </HTMLTable>
                        :
                        <HTMLTable style={{width: '100%'}}>
                            <tr style={{margin: 0}}>
                                <td style={{...titleCell, textAlign: 'center'}}>차대번호*</td>
                                <td style={{...titleCell, textAlign: 'center'}}>차량번호판</td>
                                <td style={{...titleCell, textAlign: 'center'}}>고객동의서*</td>
                            </tr>
                            <tr>
                                <ImgCell name='vinUrl'/>
                                <ImgCell name='vehiclePlateUrl'/>
                                <ImgCell name='customerConsentUrl'/>
                            </tr>
                            <tr>
                                <td style={{...titleCell, textAlign: 'center'}}>제품1</td>
                                <td style={{...titleCell, textAlign: 'center'}}>제품2</td>
                                <td style={{...titleCell, textAlign: 'center'}}>제품3</td>
                            </tr>
                            <tr>
                                <ImgCell name='work1Url'/>
                                <ImgCell name='work2Url'/>
                                <ImgCell name='work3Url'/>
                            </tr>
                            <tr>
                                <td style={{...titleCell, textAlign: 'center'}}>제품4</td>
                                <td style={{...titleCell, textAlign: 'center'}}>제품5</td>
                                <td style={{...titleCell, textAlign: 'center'}}>제품6</td>
                            </tr>
                            <tr>
                                <ImgCell name='work4Url'/>
                                <ImgCell name='work5Url'/>
                                <ImgCell name='work6Url'/>
                            </tr>

                        </HTMLTable>
                    }


                    <div>
                        <H6>비고</H6>
                        <TextArea
                            style={{width: '100%', minHeight: '100px'}} value={evWork.branchNote}
                            readOnly={(!((state === CONFIRMED || state === WORKED) && role === ROLE_PARTNER)) && !isEdit}
                            onChange={(e) => {
                                setEvWork({...evWork, branchNote: e.target.value})
                            }}/>
                    </div>
                </div>
            </SectionCard>
        </Section>

        <Section compact title={"작업이력"}
                 style={{gridArea: 'h', overflow: 'auto', display: isMobile && (!state) && 'none'}}>
            <SectionCard>
                <HTMLTable style={{width: '100%'}}>
                    <tr>
                        <td style={{...titleCell, textAlign: 'center'}}>상태</td>
                        <td style={{...titleCell, textAlign: 'center'}}>담당자</td>
                        <td style={{...titleCell, textAlign: 'center'}}>일자</td>
                    </tr>
                    {evWork.states?.map((state) => {
                        return <tr>
                            <td style={{textAlign: 'center'}}>{evWorkState[state.state]}</td>
                            <td style={{textAlign: 'center'}}>{state.user.name}</td>
                            <td style={{textAlign: 'center'}}>{moment(state.createDt).format('YY-MM-DD (ddd) HH:mm:ss')}</td>
                        </tr>;
                    })}

                </HTMLTable>
            </SectionCard>
        </Section>
    </>);

    function ImgCell({name}) {
        return <td style={{padding: 0, textAlign: 'center', height: '200px', width: '200px'}}>
            {evWork[name] ?
                <Popover
                    placement={'bottom'} targetProps={{style: {width: '100%', height: '100%', cursor: 'pointer'}}}
                    targetTagName={'div'}
                    disabled={(!((state === CONFIRMED || state === WORKED) && role === ROLE_PARTNER)) && !isEdit}
                    content={<Menu>
                        <MenuItem icon={'delete'} text="삭제" intent="danger" onClick={() => {
                            setEvWork({...evWork, [name]: null})
                        }}/>
                    </Menu>}
                >
                    <div style={{
                        width: '100%', height: '100%', backgroundImage: `url(${imgUrlMd(evWork[name])})`,
                        backgroundSize: 'cover', backgroundPosition: 'center', overflow: 'hidden'
                    }} onClick={() => {
                        if ((!((state === CONFIRMED || state === WORKED) && role === ROLE_PARTNER)) && !isEdit)
                            window.open(imgUrl(evWork[name]), '_blank')
                    }}/>
                </Popover>
                :
                <Button large icon={'upload'} disabled={(!(state === CONFIRMED && myTurn || state === WORKED && !myTurn && role === ROLE_PARTNER)) && !isEdit}
                        onClick={() => {
                            document.querySelector(`.${name}`)?.click()
                        }}>업로드</Button>
            }
            <FileInput
                style={{display: 'none'}} className={name}
                inputProps={{accept: "image/*, .heic"}}
                onInputChange={async (e) => {
                    setLoading({active: true, text: "업로드 중 입니다..."})
                    const formData = new FormData();
                    let file = e.target.files[0];
                    if (file.type === 'image/heic')
                        file = await heic2any({blob: e.target.files[0]})
                    formData.append('file', file)
                    myAxios.post(`/static/ev/${evWork.code}`, formData).then((res) => {
                        setEvWork({...evWork, [name]: res.data})
                    }).finally(() => {
                        setLoading({active: false})
                    });
                }}
            />
        </td>
    }
}